import { Typography } from '@mui/material'
import { Container,Box } from '@mui/system'
import React from 'react'
import './patners.css'
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const partnerImages = [
    { id: 1, src: './images/life.jfif', alt: 'Partner 1' },
    { id: 2, src: './images/nepra.png', alt: 'Partner 2' },
    { id: 3, src: './images/saarc.jpg', alt: 'Partner 3' },
    { id: 4, src: './images/gb.png', alt: 'Partner 4' },
    { id: 5, src: './images/wapda.jpg', alt: 'Partner 5' },
    { id: 6, src: './images/catlelahore.jfif', alt: 'Partner 6' },
    { id: 7, src: './images/waste.png', alt: 'Partner 7' },
    { id: 8, src: './images/piu.jfif', alt: 'Partner 8' },
    { id: 9, src: './images/RDA-web-logo.png', alt: 'Partner 8' },
    { id: 10, src: './images/wwf_icon.jpeg', alt: 'Partner 10' },
  
  ];
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: 150,
      height: 150,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      cursor: 'pointer',
      transition: 'transform 0.3s ease',
      '&:hover': {
        transform: 'scale(1.1)',
        
      },
    },
    image: {
      maxWidth: '70%',
      maxHeight: '100%',
    },
    sliderContainer: {
      maxWidth: '70%',
      margin: '0 auto',
      marginBottom: '3rem',
  
    },
  }));
  
const Patners = () => {
    const classes = useStyles();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  
  return (
    <Container relavent className='patner-main'>
        <Box>
            <Typography variant='h3' className='heading-client'>
            Our  Clients
            </Typography>
            <Typography variant='body1' className='body-text'>
            We have served a diverse range of clients across various industries and are committed to providing exceptional customer satisfaction.
            </Typography>
        </Box>
        <Grid container spacing={4} className='patner-grid'>
      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12} className={classes.sliderContainer}>
        <Slider {...settings}>
          {partnerImages.map((partner) => (
            <div key={partner.id} className='images-div'>
              <Paper className={classes.paper}>
                <img
                  src={partner.src}
                  alt={partner.alt}
                  className={classes.image}
                />
              </Paper>
            </div>
          ))}
        </Slider>
      </Grid>
    </Grid>
    </Container>
  )
}

export default Patners