import { Typography } from '@mui/material'
import { Container, Box } from '@mui/system'
import React from 'react'
import './certificates.css'
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
const partnerImages = [
  { id: 1, src: './images/secp.jfif', alt: 'Partner 1', link: '/Certificates/AST-SECP-Certificate.pdf' },
  { id: 2, src: './images/fbr.png', alt: 'Partner 2', link: '/Certificates/AST-NTN-Certificate.pdf' },
  { id: 3, src: './images/pta.png', alt: 'Partner 3', link: '/Certificates/AST-CVAS-Certificate.pdf' },
  { id: 4, src: './images/srb.jfif', alt: 'Partner 4', link: 'Certificates/AST-SRB(Taxpayer-Registration-Certificate).pdf' },
  { id: 5, src: './images/pra1.png', alt: 'Partner 5', link: '/Certificates/AST-PRA(Taxpayer-REgistration-Certificate).pdf' },
  { id: 6, src: './images/kpra.jpg', alt: 'Partner 6', link: '/Certificates/AST-KPRA(REgisartion-Certificate).pdf' },
  { id: 7, src: './images/BRA.png', alt: 'Partner 7', link: '/Certificates/AST-BRA-(Regisartion-Certificate).pdf' },
  { id: 8, src: './images/icoc.jpg', alt: 'Partner 8', link: '/Certificates/ICCI-Certificate.jpeg' },
  { id: 9, src: './images/iso.png', alt: 'Partner 9', link: '/Certificates/AST-ISO-9001-Certificate1.pdf' },
  { id: 10, src: './images/pak-soft.png', alt: 'Partner 10', link: '/Certificates/AST - PSEB-Company-Certificate upto May 2025.pdf' },
  { id: 11, src: '/images/pasha.png', alt: 'Partner 11', link: '/Certificates/AST-PASHA-MEMBERSHIP.pdf' },
  { id: 12, src: './images/pec.png', alt: 'Partner 12', link: '/Certificates/AST-PEC-CERTIFICATE.pdf' },
  // { id: 13, src: './images/pms.jpg', alt: 'Partner 8', link:'/Certificates/AST-ISO-9001-Certificate1.pdf' },
  { id: 13, src: './images/excise.jpg', alt: 'Partner 13', link: '/Certificates/AST-PROFESSIONAL-TAX-CERTIFICATE.png' },
  { id: 14, src: './images/dun&b.png', alt: 'Partner 14', link: '/Certificates/DUNS NUMBR - AWAMI SYSTEMS TECHNOLOGIES PVT LIMITED.pdf' },
  { id: 15, src: './images/ACS_Pakistan', alt: 'Partner 15', link: '/Certificates/AST - ACS- Registration Pakistan - (2015).pdf' },
  { id: 16, src: './images/iqracert', alt: 'Partner 16', link: '/Certificates/AST - ISO 27001 Certifiacte.pdf' },


];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 150,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',

    },
  },
  image: {
    maxWidth: '70%',
    maxHeight: '100%',
  },
  sliderContainer: {
    maxWidth: '70%',
    margin: '0 auto',
    marginBottom: '3rem',
  },
}));

const Certificates = () => {
  const classes = useStyles();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  return (
    <Container relavent className='certificate-main' id="Certificates">
      <Box>
        <Typography variant='h3' className='heading-client'>
          Certifications & Licenses
        </Typography>
        <Typography variant='body1' className='certifications-text'>
          Our company holds a wide range of certifications and licenses to ensure the highest level of quality and compliance in our products and services.
        </Typography>
      </Box>
      <Grid container spacing={4} className='certifocates-grid'>
        <Grid item xs={12} className={classes.sliderContainer}>
          <Slider {...settings}>
            {partnerImages.map((partner) => (
              <div key={partner.id} className='images-div'>
                <a
                  href={process.env.PUBLIC_URL + partner.link}
                  alt={partner.alt}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.paper}
                >
                  <img
                    src={process.env.PUBLIC_URL + partner.src}
                    alt={partner.alt}
                    className={classes.image}
                  />
                </a>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Certificates