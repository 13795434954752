import { Typography } from '@mui/material';
import { Container, Box } from '@mui/system';
import React from 'react';
import './OurPolicies.css';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const partnerImages = [
  { id: 1, title: 'Data Protection Policy', alt: 'Partner 1', link: '/Policies/AST - Data Protection Policy - (2023).pdf' },
  { id: 2, title: 'Information Security and Privacy Policy', alt: 'Partner 2', link: '/Policies/AST - Information Security and Privacy Policy (2023).pdf' },
];

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 250,
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  sliderContainer: {
    maxWidth: '50%',
    margin: '0 auto',
    marginBottom: '3rem',
  },
}));

const OurPolicies = () => {
  const classes = useStyles();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };
  

  return (
    <Container className='policies-main' id="OurPolicies">
      <Box>
        <Typography variant='h3' className='heading-client'>
          Our Policies
        </Typography>
      </Box>
      <Grid container spacing={4} className='policies-grid'>
        <Grid item xs={12} className={classes.sliderContainer}>
          <Slider {...settings}>
            {partnerImages.map((partner) => (
              <div key={partner.id} className='images-div'>
                <a
                  href={process.env.PUBLIC_URL + partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.paper}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography className="policies-title">
                    {partner.title}
                  </Typography>
                </a>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurPolicies;
